// reactstrap components
import imsTechnologiesCollege from "assets/img/ims-technologies-college.jpg";
import { Button, Col, Container, Row } from "reactstrap";
// core components
export default function LandingPage() {
  return (
    <>
      <section className="section section-lg">
        <Container>
          <h1 id="work-experience" className="text-center">
            Work experience
          </h1>
        </Container>
      </section>
      <section className="section section-lg">
        <Container>
          <Row className="row-grid justify-content-between align-items-center text-left">
            <Col lg="6" md="6">
              <hr className="line-danger" />
              <h3 className="text-white">iMS Technologies</h3>
              <p className="text-white mb-3">
                A wonderful technology company based in UK that works on digital
                trasformation. <strong>iMS Systems</strong> is a SaaS that helps
                organizations to trasform their business operations digitally. I
                was the tech lead at iMS Systems.
              </p>
              <div className="btn-wrapper mb-3">
                <a href="https://imssystems.tech">
                  <p className="category text-success d-inline">View company</p>
                  <Button
                    className="btn-link"
                    color="success"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    <i className="tim-icons icon-minimal-right" />
                  </Button>
                </a>
              </div>
              <div className="btn-wrapper">
                <div className="button-container">
                  <Button
                    className="btn-icon btn-simple btn-round btn-neutral"
                    color="default"
                    href="https://imssystems.tech"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-globe" />
                  </Button>
                  <Button
                    className="btn-icon btn-simple btn-round btn-neutral"
                    color="default"
                    href="https://www.linkedin.com/company/imssystems/"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                  {/* <Button
                    className="btn-icon btn-simple btn-round btn-neutral"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-facebook" />
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col lg="6" md="6">
              <img
                alt="..."
                className="img-fluid mt-3"
                src={imsTechnologiesCollege}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
