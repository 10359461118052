import Footer from "components/Footer/Footer";
import Navbar from "components/Navbars/Navbar";
import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import multiTenantArchitectureImage from "../assets/img/multitenant-architecture.png";
export default function ProfilePage() {
  React.useEffect(() => {
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("profile-page");
    };
  }, []);
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <div className="section">
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-center mt-5 ">Weolcome to my blogs</h1>
                <p className="text-center  mb-5">
                  I love sharing my technical knowledge that help developers
                  better themselves{" "}
                </p>
              </Col>
            </Row>

            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <hr className="line-danger" />
                <h3 className="text-white">Mastering React.js Store</h3>
                <p className="text-white mb-3">
                  Here I've comprehensively explained some advanced use cases of
                  React Context API. Implemented a redux alternative using
                  custom hooks and React Context.
                </p>
                <div className="btn-wrapper mb-3">
                  <a
                    href="https://medium.com/@reyadhossain43/mastering-react-js-store-a-comprehensive-guide-to-state-management-and-business-logic-bd4c532c0bfa"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p className="category text-success d-inline">Read here</p>
                    <Button
                      className="btn-link"
                      color="success"
                      href="https://medium.com/@reyadhossain43/mastering-react-js-store-a-comprehensive-guide-to-state-management-and-business-logic-bd4c532c0bfa"
                      rel="noreferrer"
                      target="_blank"
                      size="sm"
                    >
                      <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </a>
                </div>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <a
                      className="btn btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://medium.com/@reyadhossain43/mastering-react-js-store-a-comprehensive-guide-to-state-management-and-business-logic-bd4c532c0bfa"
                    >
                      <i className="fab fa-medium" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg="6" md="6" className="justify-content-center">
                <img
                  alt="..."
                  className="img-fluid"
                  src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*-A1Wx9YW_n_6HQzyavGxzQ.png"
                  }
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg="6" md="6">
                <hr className="line-danger" />
                <h3 className="text-white">
                  Multi-tenancy with Node.Js and MongoDB
                </h3>
                <p className="text-white mb-3">
                  Have you ever worked with multi-tenant software architecture?
                  Multi-tenancy can be quite challanging, specially if it is a
                  single database implementation the mental gymnastics can
                  become really hectice. Here I've shared a strucured way for
                  getting started with this.
                </p>
                <div className="btn-wrapper mb-3">
                  <a
                    href="https://github.com/RiadHossain43/node-multi-tenancy/blob/main/README.md"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p className="category text-success d-inline">Read here</p>
                    <Button
                      className="btn-link"
                      color="success"
                      href="https://github.com/RiadHossain43/node-multi-tenancy/blob/main/README.md"
                      rel="noreferrer"
                      target="_blank"
                      size="sm"
                    >
                      <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </a>
                </div>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <a
                      className="btn btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      rel="noreferrer"
                      target="_blank"
                      href="https://github.com/RiadHossain43/node-multi-tenancy/blob/main/README.md"
                    >
                      <i className="fab fa-github" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg="6" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={multiTenantArchitectureImage}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
